@use 'module-index' as *;
// COMPONENT [ PRELOADER ] ############################################################################################

// A.1. COMPONENT STYLE -----------------------------------

.mLoader {
    position: relative;
    width: 100%;
    min-height: 60vh;
    background-color: rgba(white, 0.9);
}
.aRipple {
    @include middle-align();
    display: block;
    width: 80px;
    height: 80px;
}
.aRipple {
    div {
        position: absolute;
        border: 4px solid $navy;
        opacity: 1;
        border-radius: 50%;
        animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
}
.aRipple {
    div {
        &:nth-child(2) {
            animation-delay: -0.5s;
        }
    }
}

@keyframes ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
        border-color: $orange;
    }
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($lg-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
    .mLoader {
        height: calc(100vh - 207px);
        min-height: auto;
    }
}

// 992px up
@media ($lg-up) {
    .mLoader {
        height: calc(100vh - 227px);
        min-height: auto;
    }
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// 1920px up
@media ($hd-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

// COMPONENT [ CHANNELS CAROUSEL ] ####################################################################################

.oCarouselChannels {
    // A.1. GLOBAL STYLE ----------------------------------

    max-width: none !important;

    .oCard {
        &.ChannelCard {
            background-color: white;
            box-shadow: 0px 4px 20px #afb4b7;
            margin-bottom: 30px;
            border-radius: 8px;
            overflow: hidden;
            .mImage {
                width: 100%;
                margin: 0;
                padding: 0;
            }
            .oCardBody {
                padding: 25px;
                .aTextLink {
                    text-decoration: none;
                }
            }
        }
    }

    .slick-slide {
        transform: scale(0.85);
        transition: 0.3s;
        display: block;
        height: auto;
    }

    .slick-dots {
        margin: 0 0 0 12px;
        list-style-type: none;
        padding: 0;
        display: block;
        text-align: center;
        li {
            display: inline-block;
            background-color: #dfe1e2;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 5px;
            &.slick-active {
                background-color: #b058f3;
            }
            &:last-child {
                margin-right: 0;
            }
            button {
                opacity: 0;
            }
        }
    }

    // A.1. END -------------------------------------------

    // B. MEDIA QUERIES -----------------------------------

    // B.1. GRACEFUL DEGRADATION -----------

    @media ($md-dn) {
    }

    @media ($xs-dn) {
        .slick-slide {
            transform: scale(0.95);
        }
        .slick-dots {
            margin: 0 0 0 30px;
        }
    }

    // B.1. END ----------------------------

    // B.2. PROGRESSIVE ENHANCEMENT --------
    // 576px up
    @media ($sm-up) {
    }

    // 768px up
    @media ($md-up) {
    }

    // 992px up
    @media ($lg-up) {
    }

    // 1200px up
    @media ($xl-up) {
    }

    // 1400px up
    @media ($xxl-up) {
    }

    // B.2. END ----------------------------

    // B. END ---------------------------------------------
}

// END OF FILE ########################################################################################################

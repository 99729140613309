@use 'module-index' as *;
// COMPONENT [ FOOTER ] ###############################################################

// A.0. VARIABLE IMPORTS ----------------------------------

@import '../../../../src/styles/module-index';

// A.0. END -----------------------------------------------

// A.1. COMPONENT STYLE -----------------------------------

.oFooter {
    background-color: $navyDark;
    padding: 45px 35px;
    .mFooterLeft {
        width: 100%;
        // background-color: red;
        order: 2;
    }
    .mFooterCenter {
        width: 100%;
        // background-color: yellow;
        order: 3;
    }
    .mFooterRight {
        width: 100%;
        // background-color: blue;
        order: 1;
    }
}

.mLogo {
    max-width: 70px;
    padding: 0;
    margin: 0 0 50px;
}
.mLogoLink {
    display: block;
}

.mFooterMenu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    .mLinkItem {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        text-decoration: none;
        color: white;
        font-weight: 800;
    }
}

.mSocialMedia {
    padding: 0;
    margin: 0 0 60px;
    list-style-type: none;
    display: flex;
    .mSocialItem {
        flex: 1 1;
        text-align: center;
    }
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
    .oFooter {
        .mFooterLeft {
            width: 25%;
            order: 1;
            align-self: flex-end;
        }
        .mFooterCenter {
            width: 45%;
            order: 2;
            align-self: flex-end;
        }
        .mFooterRight {
            width: 30%;
            order: 3;
            align-self: flex-end;
        }
    }

    .mLogo {
        margin: 0;
        max-width: 100px;
    }

    .mFooterMenu {
        display: flex;
        flex-wrap: wrap;
        .mLinkItem {
            flex: 1 1 50%;
            width: 50%;
            margin-bottom: 0;
            margin-top: 15px;
        }
    }

    .mSocialMedia {
        margin: 0;
    }
}

// 992px up
@media ($lg-up) {
    .oFooter {
        padding-top: 65px;
        .mFooterLeft {
            width: 30%;
        }
        .mFooterCenter {
            width: 40%;
        }
        .mFooterRight {
            width: 30%;
        }
    }
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
    .oFooter {
        .mFooterLeft {
            width: 40%;
        }
        .mFooterCenter {
            width: 35%;
        }
        .mFooterRight {
            width: 25%;
        }
    }
}

// 1920px up
@media ($hd-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

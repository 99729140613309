// ATOMS [ MISC ]  ####################################################################################################

@import '../.base/typography';

// A.1. GLOBAL STYLE --------------------------------------

$fnt-lt: Nunito-Light, sans-serif;
$font: Nunito-Regular, sans-serif;
$fnt-b: Nunito-Bold, sans-serif;
$fnt-sb: Nunito-SemiBold, sans-serif;
$fnt-xb: Nunito-ExtraBold, sans-serif;

$qs-bold: Quicksand-Bold, sans-serif;
$qs-med: Quicksand-Medium, sans-serif;
$quicksand: Quicksand-Regular, sans-serif;

// A.1.1. Headings ----------

h1,
h1 *,
.fntH1,
.fntH1 *,
.fnt42,
.fnt42 * {
    @include scaledFont(28, 42, 42, 30, 46, 46);
    font-family: $fnt-xb;
}

h2,
h2 *,
.fntH2,
.fntH2 * {
    @include scaledFont(28, 42, 42, 30, 36, 36);
    font-family: $fnt-xb;
}

h3 {
    font-family: $fnt-sb;
}

h4,
h5,
h6 {
    font-family: $font;
}

.fnt50,
.fnt50 * {
    @include scaledFont(40, 50, 50, 40, 50, 50);
}

.fnt40,
.fnt40 * {
    @include scaledFont(31, 40, 40, 31, 40, 40);
}

.fnt46,
.fnt46 * {
    @include scaledFont(32, 46, 46, 36, 52, 52);
}

.fnt34,
.fnt34 * {
    @include scaledFont(30, 38, 38, 34, 42, 42);
}
.fnt32,
.fnt32 * {
    @include scaledFont(23, 32, 32, 23, 38, 38);
}

.fnt30f,
.fnt30f * {
    @include font(30);
}

.fnt30,
.fnt30 * {
    @include scaledFont(22, 30, 30, 30, 36, 36);
}

.fnt26,
.fnt26 * {
    @include scaledFont(18, 26, 26, 18, 31, 31);
}

.fnt25f,
.fnt25f * {
    @include font(25);
}

.fnt24f,
.fnt24f * {
    @include font(24);
}

.fnt24l,
.fnt24l * {
    @include scaledFont(18, 24, 24, 24, 28, 28);
}

.fnt24,
.fnt24 * {
    @include scaledFont(16, 24, 24, 22, 28, 28);
}

.fnt22,
.fnt22 * {
    @include scaledFont(18, 22, 22, 24, 30, 30);
}

.fnt21f,
.fnt21f * {
    @include font(21);
}

.fnt21,
.fnt21 * {
    @include scaledFont(14, 21, 21, 14, 21, 21);
}

.fnt20l,
.fnt20l * {
    @include scaledFont(18, 20, 20, 24, 28, 28);
}

.fnt18l,
.fnt18l * {
    @include scaledFont(16, 18, 18, 21, 24, 24);
}

.fnt18f,
.fnt18f * {
    @include font(18);
}

.fnt16s,
.fnt16s * {
    @include scaledFont(14, 16, 16, 16, 20, 20);
}

.fnt16r,
.fnt16r * {
    @include scaledFont(24, 16, 16, 32, 18, 18);
}

.fnt16rs,
.fnt16rs * {
    @include scaledFont(18, 16, 16, 20, 18, 18);
}

.fnt16f,
.fnt16f * {
    @include font(16);
    line-height: 20px;
}

.fnt14r,
.fnt14r * {
    @include scaledFont(16, 14, 14, 18, 20, 20);
}

.fnt14f,
.fnt14f * {
    @include font(14);
    line-height: 16px;
}

.fnt13f,
.fnt13f * {
    @include font(13);
    line-height: 16px;
}

.fnt12f,
.fnt12f * {
    @include font(12);
    line-height: 16px;
}

.fnt12,
.fnt12 * {
    @include scaledFont(10, 12, 12, 12, 14, 14);
}

.fnt-xb,
.fnt-xb * {
    font-family: $fnt-xb;
}
.fnt-r,
.fnt-r * {
    font-family: $font;
}
.fnt-b,
.fnt-b * {
    font-family: $fnt-b;
}
.fnt-sb,
.fnt-sb *,
.fnt-pm,
.fnt-pm * {
    font-family: $fnt-sb;
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

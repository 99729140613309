// ORGANISMS [ CAROUSEL ] #############################################################################################

.oCarouselFeatured {
    // A.1. GLOBAL STYLE ----------------------------------
    .oCarouselPanel {
        padding: 0 0 50px;
    }

    .aBlockTitle {
        text-align: center;
        margin: 80px auto;
        @include title-accent();
    }

    .oCard {
        max-width: 1000px;
        margin: 0 auto;
        display: block !important;

        .cardWrapper {
            display: flex;
            width: 100%;
            flex-direction: column;
            padding: 0;
            border-radius: 8px;
        }
        &.CardBlue {
            .cardWrapper {
                background: $gradientBlue;
            }
        }
        &.CardPink {
            .cardWrapper {
                background: $gradientPink;
            }
        }
        &.CardGreen {
            .cardWrapper {
                background: $gradientGreen;
            }
        }
        &.CardBlueDark {
            .cardWrapper {
                background: $gradientBlueDark;
            }
        }
    }

    .oCardImage {
        display: inline-block;
        flex: 1 1 100%;
        width: 100%;
        vertical-align: bottom;
        margin: 0 0 25px;
        padding: 0;
        order: 2;
    }

    .oCardBody {
        order: 1;
        flex: 1 1 100%;
        display: inline-block;
        width: 100%;
        vertical-align: top;
        padding: 20px 50px 0;
        .fntH2 {
            margin: 0 auto;
            text-align: center;
        }
    }

    .aText {
        display: none;
    }

    // A.1. END -------------------------------------------

    // B. MEDIA QUERIES -----------------------------------

    // B.1. GRACEFUL DEGRADATION -----------

    @media ($md-dn) {
        .cardWrapper {
            overflow: hidden;
        }
        .oCardImage {
            padding: 25px;
            margin: 0 0 -25% 0;
        }
        .oCardBody {
            display: none;
        }

        .aTitle {
            text-align: center;
            margin-bottom: 20px;
        }
    }

    @media ($xs-dn) {
    }

    // B.1. END ----------------------------

    // B.2. PROGRESSIVE ENHANCEMENT --------
    // 576px up
    @media ($sm-up) {
    }

    // 768px up
    @media ($md-up) {
        .oCard {
            .cardWrapper {
                display: block;
                padding: 45px 0 0 20px;
            }
        }
        .oCardImage {
            max-width: 250px;
            width: 40%;
        }

        .oCardBody {
            width: 55%;
            vertical-align: top;
            padding: 20px 50px 20px;
            h2 {
                margin-bottom: 20px;
                text-align: left;
            }
        }
        .aTitle {
            &._mobile {
                display: none;
            }
        }
        .aText {
            display: block;
        }
    }

    // 992px up
    @media ($lg-up) {
    }

    // 1200px up
    @media ($xl-up) {
        margin-top: 120px;
    }

    // 1400px up
    @media ($xxl-up) {
        .oCarousel {
            max-width: 1700px;
            margin: 0 auto;
        }
    }

    // B.2. END ----------------------------

    // B. END ---------------------------------------------
}

// END OF FILE ########################################################################################################

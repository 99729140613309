// ORGANISMS [ MISCELLANEOUS ] ########################################################################################

// A.1. GLOBAL STYLE --------------------------------------

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($lg-dn) {
    .oHeader {
        .oMainNav {
            .oSubNav {
                .mNavItem {
                    .aLink {
                        margin-bottom: 8px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
    .oHeader {
        .mLangDropdown {
            text-align: right;
            .isActive {
                .aIconActive {
                    opacity: 1;
                    display: inline-block;
                }
                .aLinkText {
                    font-family: Nunito-Bold, sans-serif;
                }
            }
            .mLinkItem {
                display: inline-block;
                .mNavLink {
                    display: block;
                    text-align: right;
                    .aIconActive {
                        left: -25px;
                        margin: 0;
                    }
                    .aLinkText {
                        display: inline-block;
                        margin: 0;
                    }
                    .aLinkImage {
                        display: inline-block;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}

// 1200px up
@media ($xl-up) {
    .oHeader {
        &.fixed {
            .mSiteLogo {
                margin: 17.5px 0;
                transition: 0.3s;
            }
            .mLangBtn {
                padding: 17px 0;
                transition: 0.3s;
            }
            .oHeaderWrapper {
                max-width: 80px;
                transition: 0.3s;
            }
            .mNavItem {
                .aLink {
                    padding: 20px 0;
                    transition: 0.3s;
                }
                &::after {
                    bottom: 15px;
                }
                .oSubNav {
                    .mNavItem {
                        &:after {
                            bottom: 2px;
                        }
                        .aLink {
                            padding: 0 0 10px 0;
                        }
                    }
                }
            }
        }
    }
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

// BASE [ MIXINS ] ####################################################################################################

// A.1. POSITION EQUAL SIDES ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

@mixin position($position, $padding) {
    position: $position;
    top: $padding;
    right: $padding;
    bottom: $padding;
    left: $padding;
}

// A.1. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// B. VERTICAL ALIGN MIXIN ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// B.1. Vertical Align ------------------------------------

@mixin vertical-align($position) {
    position: $position;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

// B.1. END -----------------------------------------------

// B.1. Vertical Flex -------------------------------------

@mixin vertical-flex($height) {
    justify-content: center;
    flex-direction: column;
    height: $height;
}

// B.1. END -----------------------------------------------

// B.1. Center Align --------------------------------------

@mixin center-align() {
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

// B.1. END -----------------------------------------------

// B.1. Center Align --------------------------------------

@mixin middle-align() {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

// B.1. END -----------------------------------------------

// B. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// C. ASPECT RATIO ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

@mixin aspect-ratio($width, $aspect) {
    position: relative;
    width: $width;

    &:before {
        content: '';
        display: block;
        padding-top: $aspect;
    }
}

// C. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// D. CUSTOM MIXINS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// D.1. TITLE ACCENT --------------------------------------

@mixin title-accent() {
    display: block;
    width: 100%;
    text-align: center;
    position: relative;

    &:after {
        position: absolute;
        content: '';
        width: 12%;
        height: 10px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -15px;
        background-image: url('~/public/images/title-accent.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

// D.1. END -----------------------------------------------

// D. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// END OF FILE ########################################################################################################

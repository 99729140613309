// ORGANISMS [ TESTIMONIAL CAROUSEL ] #################################################################################

// A.1. COMPONENT STYLE -------------------------------

.oCarouselTestimonials {
    position: relative;
    overflow: hidden;

    .oCard {
        &.TestimonialCard {
            position: relative;
            background-image: url('/web/content/bg-testimonial.png');
            background-size: cover;
            border-radius: 18px;
            padding: 35px 25px;
            overflow: hidden;
            z-index: 1;
            height: 100%;
            &:after {
                content: '';
                @include position(absolute, 0);
                background: linear-gradient(
                    297.39deg,
                    rgba(#ffd4c2, 0.4) -36.47%,
                    rgba(#ff753a, 0.8) 64.88%
                );
                background-blend-mode: color-burn;
                z-index: 2;
            }
            .cardWrapper {
                position: relative;
                z-index: 3;
                height: 100%;
            }
            .mCardHeader {
                position: relative;
                margin: 0 0 35px;
            }
            .aQuoteIcon {
                left: 0;
                @include vertical-align(absolute);
            }
            .mCardTitle {
                margin-bottom: 25px;
                .mImage {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 15px 0 0;
                    padding: 0;
                }
                .aImage {
                    border-radius: 35px;
                }
                .aTitle {
                    display: inline-block;
                    padding: 0;
                    margin: 0;
                    vertical-align: middle;
                    color: white;
                }
            }
            .mText {
                color: #521800;
                padding-bottom: 30px;
            }
            .aEntryDate {
                position: absolute;
                bottom: 0;
                padding: 0;
                margin: 0;
                text-align: right;
                left: 0;
                right: 0;
                color: $brown-70;
                font-weight: 700;
            }
        }
    }
}

.chatbotTestimonials {
    .aQuoteImage {
        margin: 0 0 15px 30px;
    }
    .oCardBody {
        .aText {
            @include scaledFont(14, 21, 21, 14, 25, 25);
            color: $grey60;
            margin-bottom: 20px;
        }
        .aTitle,
        .aSubtitle {
            font-family: 'Nunito-bold';
            margin: 0;
            @include scaledFont(13, 14, 14, 13, 14, 14);
            color: $blueDark;
        }
    }

    .slick-dots li.slick-active {
        background-color: black !important;
    }
}

.slick-slider {
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
}
.oCarouselTestimonials .slick-track {
    overflow: auto;
    display: flex !important;
}
.oCarouselTestimonials .slick-slide {
    transform: scale(0.9);
    transition: 0.3s;
    display: block;
    height: auto;
}
.oCarouselTestimonials .slick-slide > div {
    height: 100%;
}
.slick-slide.slick-center {
    transform: scale(1);
    transition: 0.3s;
}
.slick-slider button {
    position: absolute;
}
.oCarouselTestimonials .slick-dots {
    list-style-type: none;
    padding: 0;
    margin: 30px 10% 0;
    display: block;
    text-align: center;
}
.slick-dots li {
    display: inline-block;
    background-color: #dfe1e2;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
}
.slick-dots li:last-child {
    margin-right: 0;
}
.oCarouselTestimonials .slick-dots li.slick-active {
    background-color: #ff7039;
}
.slick-dots li button {
    opacity: 0;
}
.slick-arrow {
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    border: 0;
    background-color: transparent;
    text-indent: 400px;
    overflow: hidden;
    width: 50px;
    background-position: center;
    background-repeat: no-repeat;
}
.slick-arrow.slick-next {
    right: 2%;
    background-image: url('/images/icon-arrow-right.svg');
}
.slick-arrow.slick-prev {
    left: 2%;
    background-image: url('/images/icon-arrow-left.svg');
}

// A.1. END -------------------------------------------

// B. MEDIA QUERIES -----------------------------------

// B.1. GRACEFUL DEGRADATION -----------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END ----------------------------

// B.2. PROGRESSIVE ENHANCEMENT --------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
    .chatbotTestimonials {
        .aQuoteImage {
            margin: 20% 0 15px 30px;
        }
        .oCardBody {
            .aTitle,
            .aSubtitle {
                text-align: right;
            }
        }
    }
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END ----------------------------

// B. END ---------------------------------------------

// END OF FILE ########################################################################################################

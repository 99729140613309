// ORGANISMS [ FOUR CARD CAROUSEL ] ###################################################################################
.oCarouselMusic {
    position: relative;
    overflow: hidden;

    // A.1. COMPONENT STYLE -------------------------------

    .oCard {
        &.MusicCard {
            position: relative;
            overflow: hidden;
            z-index: 1;
            height: 100%;

            .mImage {
                margin: 0 0 2px 0;
            }
            .aTitle {
                display: inline-block;
                padding: 0;
                margin: 0;
                vertical-align: middle;
            }
            .aText {
                color: white;
                margin: 0;
                padding: 0;
            }
        }
    }

    // A.1. END -------------------------------------------

    // B. MEDIA QUERIES -----------------------------------

    // B.1. GRACEFUL DEGRADATION -----------

    @media ($md-dn) {
    }

    @media ($xs-dn) {
    }

    // B.1. END ----------------------------

    // B.2. PROGRESSIVE ENHANCEMENT --------
    // 576px up
    @media ($sm-up) {
    }

    // 768px up
    @media ($md-up) {
        .oCard {
            &.MusicCard {
                .aText {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    // 992px up
    @media ($lg-up) {
    }

    // 1200px up
    @media ($xl-up) {
    }

    // 1400px up
    @media ($xxl-up) {
    }

    // B.2. END ----------------------------

    // B. END ---------------------------------------------
}

// END OF FILE ########################################################################################################

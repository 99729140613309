// ORGANISMS [ MISCELLANEOUS ] ########################################################################################

// A.1. GLOBAL STYLE --------------------------------------

.oDynamicPage {
    max-width: 1180px;
    margin: 0 auto 120px;
}


strong {
    font-weight: bold !important;
    font-family: Nunito-ExtraBold, sans-serif;
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

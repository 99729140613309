// ORGANISMS [ MISCELLANEOUS ] ########################################################################################

// A.1. GLOBAL STYLE --------------------------------------

// A.1.1. ARABIC RTL STYLE --------------------------------

.oMain {
    &.text_rtl {
        .fontRTL,
        .fontRTL * {
            direction: rtl !important;
        }
        .alignRTL,
        .alignRTL * {
            text-align: right !important;
        }
        .widthRTL,
        .widthRTL * {
            min-width: auto !important;
            max-width: none !important;
        }
    }
}

.oBlock {
    &.left {
        .oTextColumn {
            order: 2;
        }
        .oImageColumn {
            order: 1;
        }
    }
}

// A.1.1. END ---------------------------------------------

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
    // A.1.1. ARABIC RTL STYLE --------------------------------

    .oMain {
        &.text_rtl {
            .oDownloadCard {
                .mCardBody {
                    &.fontRTL {
                        .aTitle,
                        .aCtaWrapper {
                            padding-right: 25%;
                            max-width: none;
                        }
                    }
                }
            }
        }
    }

    // A.1.1. END ---------------------------------------------
}

// 1200px up
@media ($xl-up) {
    // A.1.1. ARABIC RTL STYLE --------------------------------

    .oMain {
        &.text_rtl {
            .oDownloadCard {
                .mCardBody {
                    &.fontRTL {
                        .aTitle,
                        .aCtaWrapper {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }

    // A.1.1. END ---------------------------------------------
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

// MOLECULES [ MISC ]  ################################################################################################

// A.1. GLOBAL STYLE --------------------------------------

.mNavLink {
    &.isActive {
        .aIconActive {
            display: inline-block;
        }
        .aLinkText {
            font-family: $fnt-b;
        }
    }
}

.mCtaList {
    display: flex;
    gap: 15px;
    .inlineCta {
        flex: 1 1;
        padding-left: 0;
        padding-right: 0;
        min-width: auto;
    }
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
    .mCtaList {
        display: block;
        .inlineCta {
            flex: 1 1;
            padding-left: 10px;
            padding-right: 10px;
            min-width: 150px;
            margin-right: 15px;
        }
    }
}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------
// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

@use 'module-index' as *;
// COMPONENT [ MENU ITEMS ] ###########################################################################################

// A.1. COMPONENT STYLE -----------------------------------

.aCarret {
    @include vertical-align(absolute);
    width: 16px;
    height: 16px;
    right: 0;
    background-image: url('/web/images/icon-carret-blue.svg');
    &.toRight {
        transform: translateY(-50%) rotate(-90deg);
    }
}

.mNavItem {
    padding-bottom: 15px;
    position: relative;
    transition: 0.45s;
    .aLink {
        transition: 0.45s;
    }
    &:after {
        position: absolute;
        left: 50%;
        bottom: 15px;
        transform: translateX(-50%);
        content: '';
        width: 0;
        height: 2px;
        border-radius: 100%;
        z-index: -1;
        background-color: $blueDark;
        border-radius: 5px;
    }

    &:hover {
        &:after {
            width: 30px;
            transition: 0.45s;
        }
    }
    &.isActive {
        &:after {
            width: 30px;
            transition: 0.45s;
        }
    }
}

.aLink {
    position: relative;
    display: block;
    color: $blueDark;
    text-decoration: none;
    font-weight: 800;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    &.hasDropdown {
        padding-right: 20px !important;
    }
    &:hover {
        color: $blueDark;
    }
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($lg-dn) {
    .mNavItem {
        display: block;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-bottom: 0;
        &:after {
            display: none;
        }
        &.isActive {
            font-family: Nunito-Bold, sans-serif;
        }
    }
    .aLink {
        text-align: left !important;
        padding: 0 !important;
    }
}

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
    .aLink {
        padding: 21px 0;
    }
}

// 992px up
@media ($lg-up) {
    .mNavItem {
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
    }

    .aLink {
        padding: 18.5px 0;
    }

    .mLinkItem {
        .mLink {
            justify-content: flex-end;
            .aLinkText {
                order: 1;
                margin-left: 0;
                margin-right: 12px;
            }
            .aLinkImage {
                order: 2;
            }
        }
    }
}

// 1200px up
@media ($xl-up) {
    .aLink {
        padding: 30px 0;
    }
    .mNavItem {
        &:after {
            bottom: 25px;
        }
    }
}

// 1400px up
@media ($xxl-up) {
}

// 1920px up
@media ($hd-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

// ATOMS [ MISC ]  ####################################################################################################

// A.1. GLOBAL STYLE --------------------------------------

html,
body {
    font-family: $font;
    color: $grey;
}

.aSectionTitle {
    &._accent {
        @include title-accent();
        margin: 0 auto 50px;
    }
}

.aTextLink {
    color: $blue;
    position: relative;
    font-weight: 800;
    cursor: pointer;
    transition: 0.45s;
    &:after {
        @include vertical-align(absolute);
        content: '';
        width: 14px;
        height: 14px;
        right: -16px;
        background-image: url('/web/images/icon-arrow-right-blue.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px;
        transition: 0.3s;
    }
    &:hover {
        transition: 0.45s;
        padding-left: 12px;
        &:after {
            transform: translateX(-850%) translateY(-50%);
            transition: 0.3s;
        }
    }
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
    .aSectionTitle {
        &._accent {
            @include title-accent();
            margin: 0 auto 80px;
        }
    }
}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

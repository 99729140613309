// BASE [ VARIABLES ] #################################################################################################

// A. MEDIA QUERY BREAKPOINTS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// A.1. BREAKPOINTS ---------------------------------------

// from 575px down
$xs-dn: 'max-width:575px';

// from 576px up
$sm-up: 'min-width:576px';

// from 767px down
$md-dn: 'max-width:767px';

// from 768px up
$md-up: 'min-width:768px';

// from 991px down
$lg-dn: 'max-width:991px';

// from 992px up
$lg-up: 'min-width:992px';

// from 1199px down
$xl-dn: 'max-width:1199px';

// from 1200px up
$xl-up: 'min-width:1200px';

// from 1399px down
$xxl-dn: 'min-width:1399px';

// from 1400px up
$xxl-up: 'min-width:1400px';

// from 1919px down
$hd-dn: 'min-width:1919px';

// from 1920px up
$hd-up: 'min-width:1920px';

//To target specific devices
$tablet: 'max-width:768px';
// A.1. END -----------------------------------------------

// A. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// B. SIZES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// B.1. PADDING & MARGINS ---------------------------------

$base-xl: 60px;
$base-lg: 45px;
$base-md: 30px;
$base: 15px;
$base-sm: 8px;
$base-xs: 4px;

// B.1. END -----------------------------------------------

// B. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// C. COLORS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

$black: #000000;
$white: #ffffff;
$white-03: rgba(244, 247, 251, 0.3);

// C.2. SITE COLOR CONFIG ---------------------------------

$color-1: #070b0e;
$color-2: #4b2653;
$color-3: #4e5616;

$orange: #fec75c;
$blue: #29b2ff;
$blueDark: #0061a2;
$blue37: #3762cc;
$blue15: #1565c0;
$navy: #003d66;
$navyDark: #051721;
$grey: #394146;
$greyDark: #838a8f;
$purple: #2e1a60;

$grey60: #394146;
$grey20: #dfe1e2;

$gradientBlue: linear-gradient(100.52deg, #ade1ff 8.1%, #29b2ff 95.08%);
$gradientBlueDark: linear-gradient(247.15deg, #0061a1 35.18%, #29b2ff 93.33%);
$gradientPink: linear-gradient(98.78deg, #eec9ff 0%, #ad6bdf 100%);
$gradientGreen: linear-gradient(98.78deg, rgba(194, 255, 248, 0.6) 0%, rgba(0, 228, 201, 0.6) 100%);
$gradientGrey: linear-gradient(white 0%, #f7f7f7 100%);

$brown-70: rgba(#521800, 0.7);

// C.2. END -----------------------------------------------
// C.2. END -----------------------------------------------

$font: nunito, sans-serif;

// C.2. END -----------------------------------------------

// C. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33% !important;
    }
}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

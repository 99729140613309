// GLOBAL [ TYPOGRAPHY ]  #############################################################################################

// A.1. FONT SOURCE ---------------------------------------

// A.1.1. NUNITO ------------
@font-face {
    font-family: Nunito-Light;
    src: url('../../fonts/Nunito/Nunito-Light.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-Light.woff') format('woff');
}
@font-face {
    font-family: Nunito-Regular;
    src: url('../../fonts/Nunito/Nunito-Regular.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-Regular.woff') format('woff');
}

@font-face {
    font-family: Nunito-Bold;
    src: url('../../fonts/Nunito/Nunito-Bold.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-Bold.woff') format('woff');
}

@font-face {
    font-family: Nunito-SemiBold;
    src: url('../../fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-SemiBold.woff') format('woff');
}

@font-face {
    font-family: Nunito-ExtraBold;
    src: url('../../fonts/Nunito/Nunito-ExtraBold.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-ExtraBold.woff') format('woff');
}

// A.1.2. QUICKSAND ---------
@font-face {
    font-family: Quicksand-Bold;
    src: url('../../fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
    src: url('../../fonts/Quicksand/Quicksand-Bold.woff') format('woff');
}
@font-face {
    font-family: Quicksand-Medium;
    src: url('../../fonts/Quicksand/Quicksand-Medium.ttf') format('truetype');
    src: url('../../fonts/Quicksand/Quicksand-Medium.woff') format('woff');
}
@font-face {
    font-family: Quicksand-Regular;
    src: url('../../fonts/Quicksand/Quicksand-Regular.ttf') format('truetype');
    src: url('../../fonts/Quicksand/Quicksand-Regular.woff') format('woff');
}

$fnt-lt: Nunito-Light, sans-serif;
$font: Nunito-Regular, sans-serif;
$fnt-b: Nunito-Bold, sans-serif;
$fnt-sb: Nunito-SemiBold, sans-serif;
$fnt-xb: Nunito-ExtraBold, sans-serif;

$qs-bold: Quicksand-Bold, sans-serif;
$qs-med: Quicksand-Medium, sans-serif;
$quicksand: Quicksand-Regular, sans-serif;

// A.1. END -----------------------------------------------

// A.2. RESPONSIVE FONT VARIABLES -------------------------
// font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));

$view-min-xs: 320;
$view-max-md: 1400;

// A.2. END -----------------------------------------------

// A.3. TYPOGRAPHY STYING ---------------------------------

// Scaled -------------------

@mixin scaledFont($sf-min-xs, $sf-max-md, $sf-max, $sf-minLH-xs, $sf-maxLH-md, $sf-maxLH) {
    font-size: calc(
        #{$sf-min-xs}px + (#{$sf-max-md} - #{$sf-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$sf-minLH-xs}px + (#{$sf-maxLH-md} - #{$sf-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    @media ($xxl-up) {
        font-size: #{$sf-max}px;
        line-height: #{$sf-maxLH}px;
    }
}
// END: Scaled --------------

// Fixed --------------------
@mixin font($size) {
    font-size: #{$size}px;
    line-height: normal;
    @media ($xxl-up) {
        font-size: #{$size}px;
        line-height: normal;
    }
}
// Fixed --------------------

// A.3.1. QUICK RESPONSIVE FONTS --------------------------

// A.3. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------
// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

// 575px down
@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------
// B. END -------------------------------------------------

// END OF FILE ########################################################################################################

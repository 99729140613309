@use 'module-index' as *;
// COMPONENT [ NAVBAR ] ###############################################################################################

// A.1. COMPONENT STYLE -----------------------------------

.oHeader {
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: white;
    &.top {
        position: fixed;
    }
    &.fixed {
        position: fixed;
        .mCtaWrapper {
            .mDownloadCta {
                text-decoration: none;
                background-color: transparent;
                padding: 0;
                transition: 0.3s;
                &:after {
                    position: absolute;
                    content: '';
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: $blue;
                }
                .aLinkText {
                    color: $blue;
                    transition: 0.3s;
                }
            }
        }
    }
}

.oContainer {
    max-width: 1440px !important;
    padding: 0 !important;
}

.mCtaWrapper {
    position: absolute;
    z-index: 100;
    right: 15px;
    top: 11px;
    .mDownloadCta {
        display: block;
        text-decoration: none;
        background-color: $blueDark;
        padding: 9px 21px;
        border-radius: 5px;
        transition: 0.3s;
        .aLinkText {
            display: block;
            color: white;
            transition: 0.3s;
        }
    }
}

.oHeaderWrapper {
    transition: 0.3s;
    position: relative;
    padding: 17px 15px;
    border-bottom: 1px solid #bdbdbd;
    background-color: white;
    z-index: 10;
}

.mSiteLogo {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 18px;
    padding: 0;
    width: 64px;
    height: 18px;
}

.mLogoLink {
    display: block;
}

.oNavList,
.mLangDropdown {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.oMainNav {
    padding: 24px 34px;
    position: absolute;
    width: 100%;
    left: 0;
    background-color: white;
    z-index: 5;
}

.menuItemWrapper {
    display: block;
    position: relative;
}

.navOpen {
    transform: translateY(0%);
    transition: 0.3s;
}
.navClosed {
    transform: translateY(-120%);
    transition: 0.6s;
}

.mLangBtn {
    display: block;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    .aGlobe {
        display: none;
    }
    .mButtonText {
        color: $blueDark;
        font-weight: 800;
        display: inline-block;
        vertical-align: middle;
    }
    .aCarret {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-left: 16px;
        path {
            stroke: $blueDark;
        }
    }
}

.mLangDropdown {
    padding-top: 16px;
}

.mLinkItem {
    display: block;
    width: 100%;
    &.langShow {
        display: none;
    }
    .mLink {
        display: flex;
        position: relative;
        align-items: center;
        text-decoration: none;
        margin-bottom: 10px;
        .aIconActive {
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            right: -25px;
        }
        .aLinkText {
            position: relative;
            color: $blueDark;
            order: 2;
            margin-left: 20px;
            font-family: Nunito-Light, sans-serif;
        }
        .aLinkImage {
            margin: 0;
            padding: 0;
            width: 12px;
            order: 1;
        }
        &.isActive {
            .aIconActive {
                display: block;
            }
            .aLinkText {
                font-family: Nunito-Bold, sans-serif;
            }
        }
        &:hover {
            .aLinkText {
                font-family: Nunito-Bold, sans-serif;
            }
        }
    }
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($lg-dn) {
    .oHeader {
        &.fixed {
            .oHeaderWrapper {
                padding: 5px 15px;
                transition: 0.3s;
            }
        }
    }
    .menuItemWrapper {
        margin: 0 0 10px 0;
    }
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
    .oHeader {
        position: relative;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }

    .oContainer {
        padding: 0 24px !important;
        display: flex;
        justify-content: space-between;
    }

    .oHeaderWrapper {
        border: 0;
        padding: 0;
        display: inline-block;
        max-width: 71px;
        flex: 1 1 110px;
    }

    .mCtaWrapper {
        display: none;
        opacity: 0;
    }

    .mSiteLogo {
        margin: 19.5px 0;
        width: 100%;
        height: auto;
    }

    .menuItemWrapper {
        display: inline-block;
        margin-left: 30px;
    }

    .oMainNav {
        position: relative;
        display: flex;
        padding: 0;
        justify-content: right;
    }

    .navOpen {
        transform: none;
        transition: 0s;
    }
    .navClosed {
        transform: none;
        transition: 0s;
    }

    .oNavList {
        display: inline-block;
    }

    .mLangSelect {
        display: inline-block;
        position: relative;
    }

    .mLangBtn {
        transition: 0.3s;
        margin-left: 30px;
        padding: 17px 0;
        .aGlobe {
            display: inline-block;
        }
        .mButtonText {
            display: none;
        }
        .aCarret {
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
        &:hover {
            transition: 0.3s;
            + .mLangDropdown {
                height: auto;
                opacity: 1;
            }
        }
    }

    .mLangDropdown {
        position: absolute;
        right: 0;
        height: 0;
        opacity: 0;
        padding: 0 25px;
        background-color: white;
        min-width: 180px;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 0 0 8px 8px;
        &:hover {
            height: auto;
            opacity: 1;
        }
    }

    .mLinkItem {
        &:first-child {
            margin-top: 25px;
        }
        &:last-child {
            margin-bottom: 25px;
        }
        .mLink {
            justify-content: flex-end;
            .aLinkText {
                order: 1;
                margin-left: 0;
                margin-right: 12px;
            }
            .aLinkImage {
                order: 2;
            }
        }
    }
}

// 1200px up
@media ($xl-up) {
    .oHeaderWrapper {
        max-width: 110px;
    }
    .oContainer {
        padding: 0 30px !important;
    }
    .mSiteLogo {
        margin: 25px 0;
    }
    .mLangBtn {
        padding: 27.5px 0;
    }
}

// 1400px up
@media ($xxl-up) {
}

// 1920px up
@media ($hd-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################
